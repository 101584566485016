import Image from 'next/image';
import logo from '../app/logo-light2.png';
import config from '@/config';

export default function Logo() {
  return (
    <Image
      src={logo}
      alt={`${config.appName} logo`}
      className="w-8"
      priority={true}
      width={32}
      height={32}
    />
  );
}
